import './index.scss'
import Swiper from 'swiper/swiper-bundle.min.js'

//banner轮播
var interleaveOffset = 0.5; //视差比值
var bannerswiper = new Swiper(".section1 .mySwiper", {
  loop: true,
  speed: 800,
  watchSlidesProgress: true,
  // effect : 'fade',
  autoplay: {
    delay: 6000,
    stopOnLastSlide: false,
    disableOnInteraction: false,
    waitForTransition: false,
  },
  pagination: {// 分页
    el: ".section1 .swiper-pagination",
    clickable: true,
  },
  on:{
    progress: function(swiper) {
		  for (var i = 0; i < swiper.slides.length; i++) {
			var slideProgress = swiper.slides[i].progress;
			var innerOffset = swiper.width * interleaveOffset;
			var innerTranslate = slideProgress * innerOffset;
			swiper.slides[i].querySelector(".slide-inner").style.transform =
			  "translate3d(" + innerTranslate + "px, 0, 0)";
		  }      
		},
		touchStart: function(swiper) {
		  for (var i = 0; i < swiper.slides.length; i++) {
			swiper.slides[i].style.transition = "";
		  }
		},
		setTransition: function(swiper, speed) {
		  for (var i = 0; i < swiper.slides.length; i++) {
			swiper.slides[i].style.transition = speed + "ms";
			swiper.slides[i].querySelector(".slide-inner").style.transition =
			  speed + "ms";
		  }
		}
  },
})

var mapswiper = new Swiper(".section5 .left .mySwiper", {
	direction: 'horizontal',
	slidesPerView: 1,
	spaceBetween: 20,
	navigation: {//按钮
    nextEl: ".section5 .left .swiper-button-next",
    prevEl: ".section5 .left .swiper-button-prev",
  },
	breakpoints: { //响应式
    768: {  //当屏幕宽度大于等于768 
      slidesPerView: 2,
      spaceBetween: 20,
			direction: 'horizontal',
    },
		1024:{
			slidesPerView: 2,
      spaceBetween: 20,
			direction: 'vertical',
		},
    1280: {  //当屏幕宽度大于等于1280
      slidesPerView: 3,
      spaceBetween: 20,
			direction: 'vertical',
    }
  },
	on:{
		slideChangeTransitionStart: function(){
      $('.section5 .left .swiper-slide').eq(this.realIndex).click()
    },
	}
})